import { FC } from 'react';
import IconProps from './IconProps';

const AttachmentIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9303 7.55555L9.07626 13.4096C8.38199 14.1038 8.38199 15.2295 9.07626 15.9237C9.77053 16.618 10.8962 16.618 11.5904 15.9237L17.2919 10.0697C18.6804 8.68118 18.6804 6.42993 17.2919 5.04139C15.9034 3.65287 13.6522 3.65287 12.2636 5.04139L6.5621 10.8955C4.4793 12.9782 4.4793 16.3551 6.5621 18.4379C8.64489 20.5207 12.0218 20.5207 14.1045 18.4379L19.6667 12.8889"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AttachmentIcon;
